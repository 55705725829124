import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'
import styled from 'styled-components'

import Head from '../layouts/Head'
import { usePageUrl } from '../lib/pages'
import Layout from './global/Layout'

// Fix for client only routes flashing 404 on refresh
const browser = typeof window !== 'undefined' && window

const Container = styled.div`
  color: #ffffff;
  min-height: calc(60vh);
  padding: 80px 15px 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${PrimaryButton} {
    display: block;
    width: 100%;
    max-width: 344px;
  }
`

const NotFoundPage: FC = () => {
  const { navigate } = usePageUrl()
  return (
    <>
      <Head title="404: Not Found" />
      <Layout>
        {browser && (
          <Container>
            <h1>Page not found.</h1>
            <p>The page you&#39;re looking for doesn&#39;t exist.</p>
            <PrimaryButton
              onClick={() => navigate('/products')}
              text="Return to Store"
              type="submit"
            />
          </Container>
        )}
      </Layout>
    </>
  )
}

export default NotFoundPage
